import Form from './component/form/index';
import './App.css';

function App() {
  return (
    <Form />
  );
}

export default App;

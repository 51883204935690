import react, { useState } from 'react';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import './style.css';

function Form() {

    const [clientAddress, setClientAddress] = useState();
    const [date, setDate] = useState();
    const [startEventHour, setStartEventHour] = useState();
    const [endEventHour, setEndEventHour] = useState();
    const [additionalRentalHours, setAdditionalRentalHours] = useState(0.00);
    const [additionalRentalGuests, setAdditionalRentalGuests] = useState(0.00);
    const [additionalSecurityHours, setAdditionalSecurityHours] = useState(0.00);
    const [additionalSecurityGuests, setAdditionalSecurityGuests] = useState(0.00);
    const [additionalJanitorialHours, setAdditionalJanitorialHours] = useState(0.00);
    const [additionalJanitorialGuests, setAdditionalJanitorialGuests] = useState(0.00);

    function calculation(rentalHour, rentalGuest, securityHour, securityGuest, janitorialHour, janitorialGuest, type) {
        try {
            var calculatedValue;

            if (type == "subTotal") {
                calculatedValue = ((Number(rentalHour) * 20.00) + (Number(rentalGuest) * 10.00) + ((Number(securityHour) * 20.00) + (Number(securityGuest) * 10.00)) + ((Number(janitorialHour) * 10.00) + (Number(janitorialGuest) * 10.00)) + 400.00);
                return calculatedValue
            }

            if (type == "tax") {
                calculatedValue = ((((Number(rentalHour) * 20.00) + (Number(rentalGuest) * 10.00) + ((Number(securityHour) * 20.00) + (Number(securityGuest) * 10.00)) + ((Number(janitorialHour) * 10.00) + (Number(janitorialGuest) * 10.00)) + 400.00) * 7.0) / 100);
                return calculatedValue
            }

            if (type == "venue") {
                calculatedValue = (((Number(rentalHour) * 20.00) + (Number(rentalGuest) * 10.00) + ((Number(securityHour) * 20.00) + (Number(securityGuest) * 10.00)) + ((Number(janitorialHour) * 10.00) + (Number(janitorialGuest) * 10.00)) + 400.00) + ((((Number(rentalHour) * 20.00) + (Number(rentalGuest) * 10.00) + ((Number(securityHour) * 20.00) + (Number(securityGuest) * 10.00)) + ((Number(janitorialHour) * 10.00) + (Number(janitorialGuest) * 10.00)) + 400.00) * 7.0) / 100));
                return calculatedValue
            }

            if (type == "venueDeposit") {
                calculatedValue = (((Number(rentalHour) * 20.00) + (Number(rentalGuest) * 10.00) + ((Number(securityHour) * 20.00) + (Number(securityGuest) * 10.00)) + ((Number(janitorialHour) * 10.00) + (Number(janitorialGuest) * 10.00)) + 400.00) / 2);
                return calculatedValue
            }

            if (type == "amountBooked") {
                calculatedValue = ((((Number(rentalHour) * 20.00) + (Number(rentalGuest) * 10.00) + ((Number(securityHour) * 20.00) + (Number(securityGuest) * 10.00)) + ((Number(janitorialHour) * 10.00) + (Number(janitorialGuest) * 10.00)) + 400.00) / 2) + 300.00);
                return calculatedValue
            }

            if (type == "balanced") {
                calculatedValue = ((((Number(rentalHour) * 20.00) + (Number(rentalGuest) * 10.00) + ((Number(securityHour) * 20.00) + (Number(securityGuest) * 10.00)) + ((Number(janitorialHour) * 10.00) + (Number(janitorialGuest) * 10.00)) + 400.00) / 2) + ((((Number(rentalHour) * 20.00) + (Number(rentalGuest) * 10.00) + ((Number(securityHour) * 20.00) + (Number(securityGuest) * 10.00)) + ((Number(janitorialHour) * 10.00) + (Number(janitorialGuest) * 10.00)) + 400.00) * 7.0) / 100));
                return calculatedValue
            }

        } catch (error) {
            console.log(error, "error")
        }
    }

    function print() {
        var css = '@page { size: landscape; }',
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

        style.type = 'text/css';
        style.media = 'print';

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        window.print();
    }

    async function download() {
        const element = document.getElementById('form-print');
        const opt = {
            margin: 10.5,
            filename: 'Contract Quote.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        };

        html2pdf().from(element).set(opt).save();

        // var html = document.getElementById('form-print').innerHTML ;
        // var blob = new Blob(['\ufeff', html], {
        //     type: 'application/msword'
        // });
        // var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        // var filename = 'Contract Quote.docx';
        // var downloadLink = document.createElement("a");
        // document.body.appendChild(downloadLink);
        // if (navigator.msSaveOrOpenBlob) {
        //     navigator.msSaveOrOpenBlob(blob, filename);
        // } else {
        //     downloadLink.href = url;
        //     downloadLink.download = filename;
        //     downloadLink.click();
        // }
        // document.body.removeChild(downloadLink);
    }

    function timeCalculation() {
        if(!startEventHour && !endEventHour){
            return '00:00'
        }
        const startTime = moment(startEventHour, 'HH:mm');
        const endTime = moment(endEventHour, 'HH:mm');
        const timeDifference = moment.duration(endTime.diff(startTime));
        const formattedTime = moment.utc(timeDifference.asMilliseconds()).format('HH:mm');
        return formattedTime
    }

    return (
        <div className="form_section" id="form-print">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col" className="bg">Client:</th>
                        <th scope="col" colSpan="4"></th>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan="4" className="p-2">
                            <textarea name="" id="" cols="20" rows="10" placeholder="Add Address..." onChange={e => setClientAddress(e.target.value)}></textarea>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan="4"><a href="#">Return Security Deposit to Address Above.</a></td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td scope="row" className="bg">Date</td>
                        <td><input type="date" placeholder="" onChange={e => setDate(e.target.value)} /></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td >Start Time</td>
                        <td >End Time</td>
                        <td >Event Time</td>
                        <td >Total Time</td>
                    </tr>
                    <tr>
                        <td className="bg">Events Hours</td>
                        <td ><input type="time" placeholder="" onChange={e => setStartEventHour(e.target.value)} /></td>
                        <td ><input type="time" placeholder="" min={startEventHour ? startEventHour : ""} onChange={e => setEndEventHour(e.target.value)} /></td>
                        <td>{timeCalculation() ? timeCalculation() : '00:00'}</td>
                        <td>{timeCalculation()}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="bg">Garden Package</td>
                        <td>Garden</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>Rate</td>
                        <td>Hours/People</td>
                        <td></td>
                    </tr>
                    {/* ================================ Rental Rate  ========================================= */}
                    <tr>
                        <td className="bg">Rental Rate</td>
                        <td>First 4 Hours</td>
                        <td className="right">$ 200.00</td>
                        <td className="right">1</td>
                        <td className="right">$ 200.00</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Additional Hours</td>
                        <td className="right">$ 20.00</td>
                        <td> <input type="text" placeholder="0" onChange={e => setAdditionalRentalHours(e.target.value)} /></td>
                        <td className="right">{'$' + (Number(additionalRentalHours) * 20.00)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Additional Guests</td>
                        <td className="right">$ 10.00</td>
                        <td><input type="text" placeholder="0" onChange={e => setAdditionalRentalGuests(e.target.value)} /></td>
                        <td className="right">{'$' + (Number(additionalRentalGuests) * 10.00)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="bg">Rental Rate Total</td>
                        <td className="right bg">{'$' + ((Number(additionalRentalHours) * 20.00) + (Number(additionalRentalGuests) * 10.00) + 200.00)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {/* ==========================  security  ================================= */}
                    <tr>
                        <td className="bg">security</td>
                        <td>First 4 Hours</td>
                        <td className="right">$ 100.00</td>
                        <td className="right">4</td>
                        <td className="right">$ 100.00</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Additional Hours</td>
                        <td className="right">$ 20.00</td>
                        <td><input type="text" placeholder="0" onChange={e => setAdditionalSecurityHours(e.target.value)} /></td>
                        <td className="right">{'$' + (Number(additionalSecurityHours) * 20.00)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Additional Guests</td>
                        <td className="right">$ 10.00</td>
                        <td><input type="text" placeholder="0" onChange={e => setAdditionalSecurityGuests(e.target.value)} /></td>
                        <td className="right">{'$' + (Number(additionalSecurityGuests) * 10.00)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="bg">security Total</td>
                        <td className="right bg">{'$' + ((Number(additionalSecurityHours) * 20.00) + (Number(additionalSecurityGuests) * 10.00) + 100.00)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {/* ==========================  Junitorial  ================================= */}
                    <tr>
                        <td className="bg">Janitorial</td>
                        <td>First 4 Hours</td>
                        <td className="right">$ 100.00</td>
                        <td className="right">1</td>
                        <td className="right">$ 100.00</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Additional Hours</td>
                        <td className="right">$ 10.00</td>
                        <td><input type="text" placeholder="0" onChange={e => setAdditionalJanitorialHours(e.target.value)} /></td>
                        <td className="right">{'$' + (Number(additionalJanitorialHours) * 10.00)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Additional Guests</td>
                        <td className="right">$ 10.00</td>
                        <td><input type="text" placeholder="0" onChange={e => setAdditionalJanitorialGuests(e.target.value)} /></td>
                        <td className="right">{'$' + (Number(additionalJanitorialGuests) * 10.00)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="bg">Janitorial Total</td>
                        <td className="right bg">{'$' + ((Number(additionalJanitorialHours) * 10.00) + (Number(additionalJanitorialGuests) * 10.00) + 100.00)}</td>
                    </tr>
                    {/* ==========================  SubTotal  ================================= */}
                    <tr>
                        <td className="bg"><b>Subtotal</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="right"><b className="right">{'$' + calculation(additionalRentalHours, additionalRentalGuests, additionalSecurityHours, additionalSecurityGuests, additionalJanitorialHours, additionalJanitorialGuests, "subTotal")}</b></td>
                    </tr>
                    <tr>
                        <td className="bg" >7.0%Tax</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="right">{'$' + calculation(additionalRentalHours, additionalRentalGuests, additionalSecurityHours, additionalSecurityGuests, additionalJanitorialHours, additionalJanitorialGuests, "tax")}</td>
                    </tr>
                    <tr>
                        <td className="bg">Venue Total</td>
                        <td><ul><li>Sub Total + Tax</li></ul></td>
                        <td></td>
                        <td></td>
                        <td className="right">{'$' + calculation(additionalRentalHours, additionalRentalGuests, additionalSecurityHours, additionalSecurityGuests, additionalJanitorialHours, additionalJanitorialGuests, "venue")}</td>
                    </tr>
                    <tr>
                        <td colSpan="2"><b>Refundable:</b> *Security Deposit</td>
                        <td></td>
                        <td></td>
                        <td className="right">$ 300.00</td>
                    </tr>
                    <tr>
                        <td className="bg">Venue Deposit</td>
                        <td><ul><li>1/2 Subtotal</li></ul></td>
                        <td></td>
                        <td></td>
                        <td className="right">{'$' + calculation(additionalRentalHours, additionalRentalGuests, additionalSecurityHours, additionalSecurityGuests, additionalJanitorialHours, additionalJanitorialGuests, "venueDeposit")}</td>
                    </tr>
                    <tr>
                        <td className="bg">Amount Due To Book</td>
                        <td><ul><li>1/2 Subtotal</li>
                            <li>+ $300.00 Security Dep.</li></ul></td>
                        <td></td>
                        <td></td>
                        <td className="right">{'$' + calculation(additionalRentalHours, additionalRentalGuests, additionalSecurityHours, additionalSecurityGuests, additionalJanitorialHours, additionalJanitorialGuests, "amountBooked")}</td>
                    </tr>
                    <tr>
                        <td className="bg">Balance Due: </td>
                        <td><ul><li>1/2 Subtotal</li>
                            <li>+ Tax</li></ul></td>
                        <td></td>
                        <td></td>
                        <td className="right">{'$' + calculation(additionalRentalHours, additionalRentalGuests, additionalSecurityHours, additionalSecurityGuests, additionalJanitorialHours, additionalJanitorialGuests, "balanced")}</td>
                    </tr>
                    <tr>
                        <td colSpan="5">*Security deposite - A full or partial refund  is returned Depending on damages that may occur during the scheduled event.</td>
                    </tr>
                    <tr>
                        <td className="bg">Conservancy use only</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="bg">Signing Date</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="bg">Event Manager</td>
                        <td>Maurice</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <div className="table_btns">
                <button onClick={print}>Print</button>
                <button onClick={download}>Downlaod</button>
            </div>
        </div>
    )

}

export default Form;